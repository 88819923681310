import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import qs from 'qs';
import Moment from '../../../../modules/react-moment';
import classnames from 'classnames';
import Utils from '../../../../utils';
import { DAY_MONTH, MONTH_DAY } from '../../../../constants';
import './AgendaTimeStatusSchedule.scss';

export function AgendaTimeStatusSchedule(props) {
  const {
    startTime,
    endTime,
    dateFormat = DAY_MONTH,
    isDuration,
    duration,
    isProfileLocation = false,
    isDataDisplayed,
    date,
    sessionDay = {},
    i18n = {},
    t,
  } = props;

  const componentClass = classnames('c-agenda-time-status-schedule', {
    'c-agenda-time-status-schedule--profile': isProfileLocation,
  });
  const isSearchMode = qs.parse(props.location?.search, {
    ignoreQueryPrefix: true,
  }).searchInput;

  const dataAsText = () => {
    return (
      <span>
        {sessionDay.date
          ? (isDataDisplayed || isSearchMode) &&
            Utils.formattedTime(
              sessionDay.date,
              dateFormat === MONTH_DAY
                ? 'dddd, MMMM D, YYYY'
                : 'dddd, D MMMM YYYY',
              i18n.language,
            )
          : sessionDay.name}
      </span>
    );
  };

  return (
    <span className={componentClass}>
      {isProfileLocation ? (
        <>
          {date && (
            <>
              <Moment
                parse="YYYY-MM-DD Z"
                format={dateFormat === MONTH_DAY ? 'MMM DD' : 'DD MMM'}
                locale={i18n.language}
              >
                {date}
              </Moment>
              ,&nbsp;
            </>
          )}
          {startTime && <span>{startTime}</span>}
        </>
      ) : (
        <>
          {dataAsText()}{' '}
          <span>
            {startTime}
            {' - '}
            {endTime}
          </span>
          {isDuration && (
            <span className="c-agenda-time-status-schedule__duration">
              {duration} {t('event.agenda.mins')}
            </span>
          )}
        </>
      )}
    </span>
  );
}

AgendaTimeStatusSchedule.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  dateFormat: PropTypes.oneOf([DAY_MONTH, MONTH_DAY]),
  isDuration: PropTypes.bool,
  isProfileLocation: PropTypes.bool,
  isDataDisplayed: PropTypes.bool,
  duration: PropTypes.number,
  date: PropTypes.string,
  sessionDay: PropTypes.object,
  i18n: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withRouter(AgendaTimeStatusSchedule));
