import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withBrand } from '../../../../providers/BrandProvider';
import {
  splitListIntoChunks,
  renderHTMLString,
  getImgixUrl,
} from '../../../../helpers';
import Link from '../../Link/Link';
import './SessionSpeakers.scss';

export function SessionSpeakers(props) {
  const {
    speakers = [],
    brand = {},
    eventPath = '',
    eventDomain = '',
    eventTenant = '',
    pageConfig: { tenantId } = {},
  } = props;

  return (
    <div className="session-speakers">
      {splitListIntoChunks(speakers, 2).map((speakersRow, i) => {
        const firstInRowRole = speakersRow[0].role;
        const secondInRowRole = speakersRow[1] ? speakersRow[1].role : null;

        const rowWithRole = !isMobile && (firstInRowRole || secondInRowRole);

        return (
          <div className="row" key={i}>
            {speakersRow.map((speaker, i) => {
              const {
                company,
                title,
                jobTitle,
                path,
                photo = {},
                fullImagePath,
                role,
                displayHonorific,
                honorific = '',
              } = speaker;
              const linkProps = {};

              if (eventPath) {
                linkProps.to = `/${eventPath}/speakers/${path}`;
                linkProps.sub = eventDomain;
                linkProps.tenant = eventTenant;
              } else {
                linkProps.to = `/speakers/${path}`;
                linkProps.event = true;
              }

              let itemWithRole = false;
              if (isMobile && role) {
                itemWithRole = true;
              }

              const backgroundImage = getImgixUrl(
                tenantId,
                fullImagePath || photo.path,
                'fit=clamp&w=80&h=80&dpr=2',
                '/images/default-silhouette.png',
              );

              return (
                <div
                  key={i}
                  className="col-xs-12 col-sm-6 session-speakers__item"
                >
                  {(rowWithRole || itemWithRole) && (
                    <div>
                      <div
                        className="session-speakers__item-role"
                        style={{
                          backgroundColor: brand.secondaryColor,
                        }}
                      >
                        {role}
                      </div>
                    </div>
                  )}
                  <Link className="session-speakers__item-link" {...linkProps}>
                    <div
                      className="session-speakers__item-photo"
                      style={{
                        backgroundImage: backgroundImage
                          ? `url(${backgroundImage})`
                          : null,
                      }}
                    />
                    <div className="session-speakers__item-text">
                      {title && (
                        <div className="session-speakers__item-name">
                          {displayHonorific && honorific && `${honorific} `}
                          {renderHTMLString(title)}
                        </div>
                      )}
                      {jobTitle && (
                        <div className="session-speakers__item-job">
                          {renderHTMLString(jobTitle)}
                        </div>
                      )}
                      {company && (
                        <div className="session-speakers__item-company">
                          {renderHTMLString(company)}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

SessionSpeakers.propTypes = {
  speakers: PropTypes.array,
  brand: PropTypes.object,
  eventPath: PropTypes.string,
  eventDomain: PropTypes.string,
  eventTenant: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withBrand(SessionSpeakers));
