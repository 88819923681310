import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { siteTypesEnum } from '../../../helpers';
import { DAY_MONTH } from '../../../constants';

import AgendaTimeStatusOnDemand from './AgendaTimeStatusOnDemand/AgendaTimeStatusOnDemand';
import AgendaTimeStatusSchedule from './AgendaTimeStatusSchedule/AgendaTimeStatusSchedule';

import './AgendaTimeStatus.scss';

export function AgendaTimeStatus(props) {
  const {
    pageConfig: { siteType } = {},
    session: {
      status,
      statusText = '',
      endTime,
      startTime,
      duration,
      date,
      dayNumber: sessionDayNumber,
    } = {},
    siteAgenda: { data: { allDates = [] } = {} } = {},
    siteHeader: { data: { dateFormat = DAY_MONTH } = {} } = {},
    isDuration = false,
    isProfileLocation = false,
    isBoldStatus = false,
    isDataDisplayed,
    textSize,
  } = props;
  const { EVENT } = siteTypesEnum();
  // On demand status now only for events
  const isOnDemand = siteType === EVENT && status === 'ON_DEMAND_CONFIRMED';
  const componentClass = classnames('c-agenda-time-status', {
    'c-agenda-time-status--medium': textSize === 'medium',
  });

  function getSessionDay() {
    return allDates.find((date) => {
      return date.dayNumber === sessionDayNumber;
    });
  }

  return (
    <span className={componentClass}>
      {isOnDemand ? (
        <AgendaTimeStatusOnDemand
          statusText={statusText}
          isBoldStatus={isBoldStatus}
        />
      ) : (
        <AgendaTimeStatusSchedule
          startTime={startTime}
          endTime={endTime}
          dateFormat={dateFormat}
          isDuration={isDuration}
          duration={duration}
          isProfileLocation={isProfileLocation}
          isDataDisplayed={isDataDisplayed}
          date={date}
          sessionDay={getSessionDay()}
        />
      )}
    </span>
  );
}

AgendaTimeStatus.propTypes = {
  pageConfig: PropTypes.object,
  siteAgenda: PropTypes.object,
  session: PropTypes.object,
  isDuration: PropTypes.bool,
  isProfileLocation: PropTypes.bool,
  isBoldStatus: PropTypes.bool,
  isDataDisplayed: PropTypes.bool,
  textSize: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    siteAgenda: state.siteAgenda,
    siteHeader: state.siteHeader,
  };
}

export default connect(mapStateToProps)(AgendaTimeStatus);
